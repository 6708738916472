<template>
    <div class="kpi-wrapper r-page" @click="popCoverHide">
        <div class="r-filters">
            <div class="r-search">
                <el-form size="small" inline @submit.native.prevent>
                    <el-form-item v-if="false">
                        <el-input placeholder="请输入问题名称关键词" v-model="query.keyword" @keydown.enter.native="search"
                            clearable></el-input>
                    </el-form-item>
                    <el-form-item v-if="false">
                        <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
                    </el-form-item>      
                    <el-form-item v-if="false">
                        <el-select v-model="query.status" @change="search">
                            <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                      <el-radio-group v-model="query.type" @change="search">
                        <el-radio-button label="village">村庄统计</el-radio-button>
                        <el-radio-button label="department">负责部门统计</el-radio-button>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item>
                      <el-date-picker
                          v-model="query.year"
                          format="yyyy"
                          value-format="yyyy"
                          type="year"
                          placeholder="请选择年份"
                          @change="search"
                          :clearable="false"
                      >
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                      <el-select v-model="query.month" @change="search" clearable>
                        <el-option v-for="item in 12" :key="item" :label="item+'月'" :value="item"></el-option>
                      </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div class="r-toolbar" v-if="false">
                <el-form inline size="small">
                    <el-form-item style="margin-right:0">
                        <div class="r-flex">
                            <el-popover placement="bottom-end" width="400" trigger="manual" v-model="popShow"
                                @click.stop.native>
                                <div>
                                    <div class="r-pb-20">筛选条件</div>
                                    <el-form size="small">
                                        <el-form-item>
                                            <r-tag-select v-model="searchQuery.employee" class="r-w-max"
                                                :collapseTags="true" placeholder="请选择负责人"
                                                @focus="handleEmployee('checkbox', '请选择负责人', 'employee')"
                                                labelKey="name"></r-tag-select>
                                        </el-form-item>
                                        <el-form-item>
                                            <r-tag-select v-model="searchQuery.department" class="r-w-max"
                                                :collapseTags="true" placeholder="请选择负责部门"
                                                @focus="handleChangeSelect('checkbox', '请选择负责部门', 'department')"
                                                labelKey="name"></r-tag-select>
                                        </el-form-item>
                                        <el-form-item>
                                            <div class="r-flex">
                                                <el-date-picker class="r-w-max" v-model="searchQuery.date" type="daterange" range-separator="至"
                                                    value-format="yyyy-MM-dd" start-placeholder="计划开始日期" end-placeholder="计划结束日期">
                                                </el-date-picker>
                                            </div>
                                        </el-form-item>
                                        <el-form-item>
                                            <div class="r-flex r-row-right">
                                                <el-button @click="popHide">取消</el-button>
                                                <el-button type="primary" @click="searchConfirm">确认</el-button>
                                            </div>
                                        </el-form-item>
                                    </el-form>
                                </div>
                                <el-button slot="reference" class="sx-btn" @click="popOpen"
                                    :class="{ active: searchActive }">
                                    <r-icon name="shaixuan" type="task" size="16"></r-icon>
                                </el-button>
                            </el-popover>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="r-content">
            <r-table ref="table" :loader="loader" :columns="tableColumns" height="100%"
                :cell-class-name="cellClassName" @cell-click="detailOpen" row-key="id" @sortChange="sortChange">
            </r-table>
        </div>
        <dep-role-modal :config="modalConfig" v-model="dialogVisible" @confirm="handleConfirm"></dep-role-modal>
    </div>
</template>
<script>
import {approveList, approveStatusList, statisticsList} from "@/api/question";
import DepRoleModal from "@/components/dialog/DepRoleModal";
import {  cloneDeep, isObjectNone } from "@/utils";
const defaultQuery = {
    keyword: '',
    type: 'village',
    year: (new Date()).getFullYear().toString(),
    month: parseInt((new Date()).getMonth() + 1),
    orderBy: '',
    orderBySort: '',
    date: ['', ''],
}
export default {
    components: { DepRoleModal},
    data() {
        return {
            modalConfig: {},
            dialogVisible: false,
            query: cloneDeep(defaultQuery),
            searchQuery: cloneDeep(defaultQuery),
            popShow: false,
            columns:[
                {
                    label:'村庄',
                    prop:'name',
                    'min-width':'150px'
                },
                {
                    label:'总数量',
                    prop:'count',
                    'width':'100px',
                    sortable: 'custom'
                },
                {
                    label:'未整改',
                    prop:'notStart',
                    'min-width':'100px',
                    sortable: 'custom'
                },
                {
                    label:'整改中',
                    prop:'inProgress',
                    'min-width':'100px',
                    sortable: 'custom'
                },
                {
                    label:'审核中',
                    prop:'auditing',
                    'min-width':'100px',
                    sortable: 'custom'
                },
                {
                    label:'已整改',
                    prop:'complete',
                    'min-width':'100px',
                    sortable: 'custom'
                },
                {
                    label:'已超时',
                    prop:'timeout',
                    'min-width':'100px',
                    sortable: 'custom'
                },
                {
                    label:'问题扣分',
                    prop:'deductScore',
                    'min-width':'100px',
                    sortable: 'custom'
                },
                {
                    label:'整改得分',
                    prop:'obtainScore',
                    'min-width':'100px',
                    sortable: 'custom'
                },
                {
                    label:'当月合计分数',
                    prop:'totalScore',
                    'min-width':'150px',
                    sortable: 'custom'
                }
            ],
            statusList:[]
        }
    },
    computed: {
        searchActive() {
            return this.query.employee.length > 0 || this.query.department.length > 0 || this.query.date[0] || this.query.date[1];
        },
        tableColumns() {
            let columns = cloneDeep(this.columns);
            if (!this.query.month) {
                for (let i = 0; i < columns.length; i++) {
                    if (columns[i].prop === 'totalScore') {
                      columns.splice(i, 1);
                      break;
                    }
                }
            }
            columns[0].label = this.query.type === 'village' ? '村庄' : '部门';
            return columns;
        }
    },
    methods: {
        loader(page, pageSize) {
            let form = this.getFormData();
            return statisticsList({ page, pageSize, ...form }).then(res => {
                return res.data;
            })
        },
        refresh(page) {
            this.$refs.table.refresh(page || '');
        },
        search() {
            this.$refs.table.refresh(1);
        },
        handleChangeSelect(selectType, title, model) {
            this.modalConfig = {
                type: 1,
                selectType,
                title,
                checkStrictly: true,
                model,
                checkList: this.searchQuery[model] || [],
            };
            this.dialogVisible = true;
        },
        handleEmployee(selectType, title, model) {
            this.popLock = true;
            this.modalConfig = {
                type: 1,
                selectType,
                title,
                checkStrictly: true,
                model,
                checkList: this.searchQuery[model] || [],
                isStaff: 1,
                onlyStaff: 1
            };
            this.dialogVisible = true;
        },
        handleConfirm(val) {
            this.searchQuery[this.modalConfig['model']] = val;
            this.dialogVisible = false;
        },
        cellClassName({ row, column }) {
            return  column.property == 'title' ? 'hover--opacity' : '';
        },
        detailOpen(row, column) {
            if(column.property != 'title') return;
            this.$refs.detail.open(row,true);
        },
        sortChange(order) {
            this.query = Object.assign(this.query, order);
            this.$nextTick(() => {
                this.search();
            })
        },
        detailClose(isUpdate = false) {
            if (isUpdate) {
                this.refresh();
            }
        },
        tableRefresh() {
            this.$refs.table.columnsRefresh();
        },
        popOpen() {
            this.popShow = !this.popShow;
            if (this.popShow) {
                this.searchQuery = cloneDeep(this.query);
            }
        },
        popHide() {
            this.popShow = false;
        },
        popCoverHide() {
            this.popHide();
        },
        searchConfirm() {
            this.popHide();
            this.query = cloneDeep(this.searchQuery);
            this.search();
        },
        getFormData(){
            let form = cloneDeep(this.query);
            form.completedDate = form.date[0] ? form.date : [];
            //form.responsibleDepartmentId = form.department.map(el => el.id);
            //form.responsibleEmployeeId = form.employee.map(el => el.id);
            return form;
        },
        approveStatusList(){
            approveStatusList().then(res=>{
                this.statusList = res.data.map(el=>{
                    return {
                        label:el.label,
                        value:el.status
                    }
                });
            })
        }
    },
    mounted() {
      this.$refs.table.sort('totalScore', 'descending');
    },
    created() {
        if (!isObjectNone(this.$route.params)) {
            this.query = Object.assign(this.query, this.$route.params);
        }
        // this.approveStatusList();
    }

}
</script>

<style lang="scss" scoped>
.kpi-wrapper {
    .item-menu {
        opacity: 0;
        transition: opacity .2s;
        padding: 0px 5px;
        color: var(--primary);
    }

    .sx-btn {
        padding: 7px 15px;

        &.active {
            border-color: var(--primary);
            color: var(--primary);
        }
    }

    .table-expend {
        &.menu-show {
            .item-menu {
                opacity: 1;
            }
        }

        &:hover {
            .item-menu {
                opacity: 1;
            }
        }
    }

    .count-wrapper {
        font-size: 13px;
    }
}
</style>