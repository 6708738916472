<template>
    <r-drawer ref="rDialog" :dialogTitle="title" size="800px" @close="reset" @submit="submit">
        <div class="dialog-content" 
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-background="rgba(255,255, 255, 0.8)">
            <el-form size="small" label-position="top" :rules="rules" :model="form" ref="form">
                <el-form-item prop="title" label="问题标题">
                    <el-input v-model="form.title" placeholder="请输入问题标题"></el-input>
                </el-form-item>
                <el-form-item prop="description" label="问题描述">
                    <el-input v-model="form.description" placeholder="请输入问题描述" type="textarea"
                        :autosize="{ minRows: 4, maxRows: 8 }"></el-input>
                </el-form-item>
                <el-form-item prop="files" label="问题照片">
                    <uploadImage v-model="form.files" @change="checkFile"></uploadImage>
                </el-form-item>  
                <el-form-item prop="address" label="发现位置">
                    <template #label>
                        <span class="r-pr-10">发现位置</span>
                        <el-button type="primary" icon="el-icon-map-location" size="mini" @click="mapOpen">地图选点</el-button>
                        <span class="main-color" v-if="form.lng">（当前坐标：{{form.lng}}，{{form.lat}}）</span>
                    </template>
                    <el-input type="textarea" v-model="form.address" placeholder="请输入发现位置"></el-input>
                </el-form-item>
                <el-form-item prop="categoryId" label="问题分类">
                    <el-cascader v-model="form.categoryId" :options="options" placeholder="请选择问题分类" class="r-w-max"
                        :props="{ emitPath: true, value: 'id', label: 'title', checkStrictly: true }"
                        @change="cChange" filterable></el-cascader>
                </el-form-item>
                <el-form-item prop="villageId" label="村庄">
                    <el-cascader v-model="form.villageId" :options="cityList" placeholder="请选择村庄" class="r-w-max"
                        :props="{ emitPath: true, value: 'id', label: 'name', checkStrictly: true }"
                        @change="cChange" filterable></el-cascader>
                </el-form-item>
                <el-form-item label="负责部门" prop="department">
                    <r-tag-select v-model="form.department" labelKey="name" :closable="false" placeholder="`请选择负责部门"
                        max @focus="handleChangeSelect('radio', '请选择负责部门', 'department')"></r-tag-select>
                </el-form-item> 
                <el-form-item label="负责人" prop="employee">
                    <r-tag-select v-model="form.employee" labelKey="name" :closable="false" placeholder="`请选择负责部门"
                        max @focus="handleEmployee('radio', '请选择负责人', 'employee')"></r-tag-select>
                </el-form-item>
                <el-form-item label="整改时间" prop="date">
                    <el-date-picker
                    v-model="form.date"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                    :clearable="false"
                    :picker-options="{
                        disabledDate
                    }">
                    </el-date-picker>
                </el-form-item>      
                <el-form-item class="is-required" prop="scoreCondition">
                    <template #label>
                        <span>督导节点设置</span>
                    </template>
                    <div class="r-pb-20 r-flex">
                        <p class="r-pr-5 tips-color">发现问题扣：</p>
                        <el-input-number v-model="form.score" :min="1"></el-input-number>
                        <p class="r-pl-10 tips-color">分</p>
                    </div>
                    <div>
                        <r-table :columns="columns" :data="form.scoreCondition" :auto-height="true"> 
                            <template v-slot:date="{row}">
                                <div class="r-flex">
                                    <el-date-picker
                                    v-model="row.date"
                                    type="date"
                                    placeholder="请选择日期" :clearable="false"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="{
                                        disabledDate:pickerDisabledDate
                                    }">
                                    </el-date-picker>
                                    <p class="r-pl-10 r-pr-10 tips-color">
                                        前首次提交整改得
                                    </p>
                                    <el-input-number :min="0" :precision="2" v-model="row.score"></el-input-number>
                                    <p class="r-pl-10 tips-color">分</p>
                                </div>
                            </template>
                            <template v-slot:handle_header="{row}">
                                <div class="r-flex r-row-bet r-w-max">
                                    <span class="r-pr-10">操作</span>
                                    <el-button icon="el-icon-plus" type="text" size="small" @click="addScoreCondition">添加</el-button>
                                </div>
                            </template>
                            <template v-slot:handle="{$index}">
                                <div class="r-flex r-row-bet r-w-max">
                                    <el-link type="primary" @click="dateDelete($index)">删除</el-link>
                                </div>
                            </template>
                        </r-table>
                    </div>
                </el-form-item>
            </el-form>
            <dep-role-modal :config="modalConfig" v-model="dialogVisible" @confirm="handleConfirm"></dep-role-modal>
            <apmMap ref="apmMap"></apmMap>
        </div>
    </r-drawer>
</template>
<script>
import {  questionCategoryList,detail,save } from "@/api/question";
import { getCityList } from "@/api/common";
import { cloneDeep, isArray } from "@/utils";
import uploadImage from "@/components/imageUpload/upload-image";
import DepRoleModal from "@/components/dialog/DepRoleModal";
import apmMap from "@/components/map";
import moment from "moment";
const defaultForm = {
    title: '',
    description: '',
    address: '',
    lat: '',
    lng: '',
    files: [],
    department: [],
    employee: [],
    date: [],
    scoreCondition: [],
    score: 0,
    villageId: '',
    categoryId: ''
}
export default {
    components: {
        uploadImage,
        DepRoleModal,
        apmMap
    },
    data() {
        let that = this;
        return {
            form: cloneDeep(defaultForm),
            title: '',
            rules: {
                title: [
                    { required: true, message: '请输入问题标题', trigger: ['change', 'blur'] }
                ],
                files: [
                    { required: true, message: '请上传问题照片', trigger: ['change', 'blur'] }
                ],
                description: [
                    { required: true, message: '请填写问题描述', trigger: ['change', 'blur'] }
                ],
                address: [
                    { required: true, message: '发现位置不能为空', trigger: ['change', 'blur'] }
                ],
                categoryId: [
                    { required: true, message: '请选择问题分类', trigger: ['change', 'blur'] }
                ],
                villageId: [
                    { required: true, message: '请选择村庄', trigger: ['change', 'blur'] }
                ],
                employee: [
                    { required: true, message: '请选择负责人', trigger: ['change', 'blur'] }
                ],
                date: [
                    { required: true, message: '请选择整改时间', trigger: ['change', 'blur'] }
                ],
                scoreCondition: [
                    { validator: that.checkScoreCondition, trigger: ['change', 'blur'] },
                ]
            },
            options: [],
            cityList: [],
            modalConfig: {},
            dialogVisible:false,
            columns:[
                {
                    label:'节点设置',
                    prop:'date'
                },
                {
                    label:'操作',
                    prop:'handle',
                    width:120,
                }
            ],
            loading:true
        }
    },
    methods: {
        open(id = null, title = '新建问题分类') {
            this.title = title;
            this.init(id);
            this.getList();
            this.getCityList();
            this.$refs.rDialog.handleOpen();
        },
        getList() {
            questionCategoryList().then(res => {
                this.options = res.data;
            })
        },
        getCityList() {
            getCityList().then(res => {
                this.cityList = res.data;
            })
        },
        reset() {
            this.form = cloneDeep(defaultForm);
            this.loading = true;
        },
        getFormData(){
            let form = cloneDeep(this.form);
            form.responsibleDepartmentId =form.department[0]?.id;
            form.responsibleEmployeeId = form.employee[0]?.id;
            form.startTime = form.date[0];
            form.endTime = form.date[1];
            form.files = form.files.map(el => {
                return el?.url ? el : {path:el,name:''}
            })
            return form;
        },
        submit(done) {
            if(this.loading){
                done(false);
                return;
            }
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let form = this.getFormData();
                    save(form).then(res => {
                        this.$message.success('提交成功');
                        done(true);
                        this.$emit('confirm');
                    })
                        .catch(err => {
                            done(false);
                        })
                } else {
                    done(false);
                }
            });
        },
        cChange(val) {
            console.log(val)
        },
        checkFile() {
            this.$refs.form.validateField('files')
        },
        handleChangeSelect(selectType, title, model) {
            this.modalConfig = {
                type: 1,
                selectType,
                title,
                checkStrictly: true,
                model,
                checkList: this.form[model] || [],
            };
            this.dialogVisible = true;
        },
        handleEmployee(selectType, title, model) {
            this.modalConfig = {
                type: 1,
                selectType,
                title,
                checkStrictly: true,
                model,
                checkList: this.form[model] || [],
                isStaff: 1,
                onlyStaff: 1
            };
            this.dialogVisible = true;
        },
        handleConfirm(val) {
            this.form[this.modalConfig['model']] = val;
            this.dialogVisible = false;
        },
        addScoreCondition(){
            this.form.scoreCondition.push({
                score:0,
                date:''
            })
        },
        dateDelete(index){
            this.form.scoreCondition.splice(index,1);
        },
        disabledDate(date){
            let nowDate = new Date();
            let _date = new Date(date);
            _date.setDate(_date.getDate() + 1);
            return _date.getTime() < nowDate.getTime();
        },
        pickerDisabledDate(date){
            let maxDate = moment(this.form.date[1]);
            let minDate = moment(this.form.date[0]);
            let _date = moment(date);
            return _date < minDate || _date > maxDate;
        },
        init(id){
            detail({id,isApproved:0}).then(res=>{
                //初始化数据
                this.form.scoreCondition = res.data.scoreCondition.map(el=>{
                    el.score = parseFloat(el.score);
                    return el;
                });
                this.form.id = res.data.id;
                this.form.score = res.data.score;
                this.form.date = [res.data.startTime,res.data.endTime];
                this.form.lat = res.data.lat;
                this.form.lng = res.data.lng;
                this.form.villageId = res.data.villageId;
                this.form.categoryId = res.data.categoryId;
                this.form.address = res.data.address;
                this.form.title = res.data.title;
                this.form.description = res.data.description;
                this.form.files = res.data.files.map(el=>{
                    el.url = el.path;
                    return el;
                });
                this.form.department = res.data.responsibleDepartmentName ?  [
                    {
                        label:res.data.responsibleDepartmentName,
                        value:res.data.responsibleDepartmentId,
                        id:res.data.responsibleDepartmentId,
                        departmentId:res.data.responsibleDepartmentId,
                        departmentName:res.data.responsibleDepartmentName,
                        name:res.data.responsibleDepartmentName
                    }
                ] : [];
                this.form.employee = [
                    {
                        label:res.data.responsibleEmployeeName,
                        value:res.data.responsibleEmployeeId,
                        id:res.data.responsibleEmployeeId,
                        employeeId:res.data.responsibleEmployeeId,
                        nickname:res.data.responsibleEmployeeName,
                        name:res.data.responsibleEmployeeName
                    }
                ];
            })
            .catch(err=>{
                console.log(err)
            })
            .finally(()=>{
                this.loading = false;
            })
        },
        mapOpen(){
            this.$refs.apmMap.open({
                form:{
                    latitude: this.form.lat,
                    longitude: this.form.lng,
                    address: this.form.address,
                },
                success:(res)=>{
                    this.form.lat = res.latitude;
                    this.form.lng = res.longitude;
                    this.form.address = res.address;
                }
            });
        },
        checkScoreCondition(rule, value, callback){
            if(this.form.scoreCondition.length == 0){
                callback(new Error('请添加时间节点'));
            }else if(this.form.scoreCondition.some(el=>!el.date)){
                callback(new Error('存在未选择日期的督导节点'));
            }else if(this.form.scoreCondition.some(el=>el.score > this.form.score)){
                let index = this.form.scoreCondition.findIndex(el=>el.score > this.form.score);
                callback(new Error(`第${index+1}个节点分数不能大于当前设置的扣分数`));
            }else if(this.form.scoreCondition.some(el=>moment(el.date) < moment(this.form.date[0]) || moment(el.date) > moment(this.form.date[1]))){
                let checkAfter = this.form.scoreCondition.findIndex(el=>moment(el.date) > moment(this.form.date[1]));
                let checkBefore = this.form.scoreCondition.findIndex(el=>moment(el.date) < moment(this.form.date[0]));
                if(checkAfter !=-1){
                    callback(new Error(`第${checkAfter+1}个节点日期不能大于整改结束时间`));
                }else{
                    callback(new Error(`第${checkBefore+1}个节点日期不能小于整改开始时间`));
                }
            }else{
                callback();
            }
        }
    }



}
</script>

<style lang="scss" scoped>
.dialog-content {
  ::v-deep .el-input-number .el-input__inner{
    text-align: center;
  }
}
</style>