<template>
  <r-drawer ref="rDialog" :dialogTitle="title" size="800px" @close="reset" @submit="submit">
    <div class="dialog-content">
      <el-form size="small" label-position="top" :rules="rules" :model="form" ref="form">
        <el-form-item prop="title" label="文章标题">
          <el-input v-model="form.title" placeholder="请输入文章标题"></el-input>
        </el-form-item>
        <el-form-item label="文章分类" prop="categoryId">
          <el-select v-model="form.categoryId" filterable placeholder="请输入关键词" class="r-w-max" clearable>
            <el-option :label="item.name" :value="item.id" v-for="item in categoryList" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="缩略图">
          <single-image-upload v-model="form.thumb"></single-image-upload>
        </el-form-item>
        <el-form-item label="是否为banner">
          <el-radio-group v-model="form.isBanner">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="可见部门">
          <r-tag-select v-model="form.department" labelKey="name" :collapseTags="true" placeholder="`请选择可见部门" max
            @focus="handleChangeSelect('checkbox', '请选择可见部门', 'department')"></r-tag-select>
        </el-form-item>
        <el-form-item prop="content" label="文章内容" class="is-required">
          <edit v-model="form.content" v-if="isInit" :disabled="loading"></edit>
        </el-form-item>
        <el-form-item prop="sort" label="排序">
          <el-input-number v-model="form.sort" controls-position="right" :min="1" :max="10000"></el-input-number>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="form.status">
            <el-radio :label="0">关闭</el-radio>
            <el-radio :label="1">开启</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <dep-role-modal :config="modalConfig" v-model="dialogVisible" @confirm="handleConfirm"></dep-role-modal>
  </r-drawer>
</template>
<script>
import { save, categoryList } from "@/api/article";
import { cloneDeep, isArray } from "@/utils";
import edit from "@/components/edit";
import SingleImageUpload from "@/components/imageUpload/singleImageUpload"
import DepRoleModal from "@/components/dialog/DepRoleModal";

const defaultForm = {
  title: '',
  categoryId: '',
  content: '',
  thumb: '',
  department: [],
  sort: 0,
  isBanner: 0,
  status: 1,
}
export default {
  components: { edit, SingleImageUpload, DepRoleModal },
  data() {
    const checkContent = (rule, value, callback) => {
      if (!value || value == '<p><br></p>') {
        return callback(new Error('文章内容不能为空'));
      }

      callback();
    };
    return {
      form: cloneDeep(defaultForm),
      title: '',
      loading: false,
      isInit: false,
      dialogVisible: false,
      categoryList: [],
      modalConfig: {},
      rules: {
        title: [
          { required: true, message: '请输入文章标题', trigger: ['change', 'blur'] }
        ],
        categoryId: [
          { required: true, message: '请选择文章分类', trigger: ['change', 'blur'] }
        ],
        content: [
          { validator: checkContent, message: '请填写文章内容', trigger: ['change', 'blur'] },

        ]
      },
      options: []
    }
  },
  methods: {
    open(form = null, title = '新建文章') {
      this.getCategoryList()
      this.title = title;
      if (form) {
        this.form = Object.assign(cloneDeep(defaultForm), form);
      }
      this.$refs.rDialog.handleOpen();
      //触发初始化
      this.$nextTick(() => {
        this.$refs['rDialog']?.clearValidate && this.$refs['rDialog'].clearValidate();
        this.isInit = true;
      })
    },
    reset() {
      this.form = cloneDeep(defaultForm);
    },
    getCategoryList() {
      categoryList({ name, id: this.form?.id || '', page: 1, pageSize: 20 }).then(res => {
        this.categoryList = res.data.list;
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.pidLoading = false;
      })
    },
    submit(done) {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let form = cloneDeep(this.form);
          //填充首图
          if(!form.id && !form.thumb){
            form.thumb = this.getFirstImg(form.content);
          }
          save(form).then(res => {
            this.$message.success('提交成功');
            done(true);
            this.$emit('confirm');
          }).catch(err => {
            done(false);
          })
        } else {
          done(false);
        }
      });
    },
    handleChangeSelect(selectType, title, model) {
      this.modalConfig = {
        type: 1,
        selectType,
        title,
        checkStrictly: true,
        model,
        checkList: this.form[model] || [],
      };
      this.dialogVisible = true;
    },
    handleConfirm(val) {
      this.form[this.modalConfig['model']] = val;
      if (this.modalConfig['model'] == 'employee') {
        this.setDep('employee', val[0]);
      } else if (this.modalConfig['model'] == 'collaborateEmployee') {
        this.setDep('collaborateEmployee', val);
      }
      this.dialogVisible = false;
    },
    getFirstImg(html) {
      var arrImg = []
      html.replace(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi, function (match, capture) {
        arrImg.push(capture)
      })
      if (arrImg != null && arrImg.length > 0) {
        return arrImg[0].replace(process.env.VUE_APP_IMG_URL,'/')
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-content {
  height: 250px;
}
</style>